<template>
  <span class="flex flex-wrap gap-1">
    <span v-for="item in entries" :key="item">
      <v-chip size="20" class="px-2 py-1">
        <span class="fit-content">{{ item }}</span>
      </v-chip>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    seats: { type: Array as PropType<string[]> },
  },
  computed: {
    entries() {
      const x = this.seats || [];

      return x.map((item) => {
        // Format event seats.
        // TODO: make more compatible with backend.
        const [a, b] = item.split("|");
        const [, table] = a?.split(":");
        const [, seat] = b?.split(":");

        // Safety catch. Incase we change the backend format
        // and forgot about this
        if (!table || !seat) {
          return item;
        }

        return `Table ${table}, Seat ${seat}`;
      });
    },
  },
});
</script>
